import React from 'react'
import Helmet from 'react-helmet'
import { Player, ControlBar } from 'video-react'

import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import wordpressLogo from '../assets/images/wordpress-logo.png'
import jqueryLogo from '../assets/images/jquery-logo.png'
import scssLogo from '../assets/images/scss-logo.png'
import gulpLogo from '../assets/images/gulp-logo.png'
import dockerLogo from '../assets/images/docker-logo.png'
import medialinkLogo from '../assets/images/medialink-green.png'
import medialinkVideo from '../assets/videos/medialink-video.mp4'

const Landing = props => (
  <Layout>
    <Helmet
      title="Tyler Kemme | Medialink"
      meta={[
        {
          name: 'description',
          content: 'Wordpress Site Redesign',
        },
        {
          name: 'keywords',
          content: 'medialink, wordpress, jquery, scss, gulp, docker',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>

    <BannerLanding
      title="Medialink"
      description="Wordpress Site Redesign"
      to="https://www.medialink.com/"
      backgroundImage={medialinkLogo}
    />

    <div id="main" className="landing-page">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>The Project</h2>
          </header>
          <p>
            When Medialink needed to redesign their company's website, we chose
            to make a Wordpress site using JQuery and SCSS according to comps
            created by our designers. We used Docker to develop the site locally
            it was hosted on Azure.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <div className="logo-soup">
            <img
              src={wordpressLogo}
              alt="Wordpress logo"
              className="logo-soup-individual"
            />
            <img
              src={jqueryLogo}
              alt="JQuery logo"
              className="logo-soup-individual"
            />
            <img
              src={scssLogo}
              alt="SCSS logo"
              className="logo-soup-individual"
            />
            <img
              src={gulpLogo}
              alt="Gulp logo"
              className="logo-soup-individual"
            />
            <img
              src={dockerLogo}
              alt="Docker logo"
              className="logo-soup-individual"
            />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h2>Technologies Used</h2>
              </header>
              <p>
                This Wordpress site was made using the following front-end
                technologies and developed locally using Docker.
                <br />
                <br />
                <ul>
                  <li>
                    <a
                      href="https://wordpress.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wordpress
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://jquery.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JQuery
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://sass-lang.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SCSS
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://gulpjs.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gulp
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.docker.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Docker
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </section>
        <section>
          <a
            href="https://www.medialink.com/"
            className="image"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Player autoPlay muted loop>
              <source src={medialinkVideo} />
              <ControlBar disableCompletely />
            </Player>
          </a>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>How I contributed</h3>
              </header>
              <p>
                On this site I was responsible for implementing the design for
                about half the pages on the website. In addition to front-end
                work, I was also responsible for setting up Wordpress using the{' '}
                <a
                  href="https://www.advancedcustomfields.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ACF
                </a>{' '}
                plugin so that Medialink could add content to the site without
                needing help from our development team.
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Landing
